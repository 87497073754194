//# Aplication banner
//
//*

.c-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
    color: $white;
    transition: $transition;

    &.is-red {
        color: $red;
    }

    &.is-footer {
        opacity: 0;
    }

    &-wrap {
        display: flex;
        align-items: center;
        justify-content: center;
        @include zoom(padding-top, 20px);
    }

    &-logo {
        @include zoom(width, 155px);
        @include zoom(height, 30px);

        svg {
            width: 100%;
            height: 100%;
        }
    }
}
.cookie_block {
  position: fixed;
  width: 100%;
  height: auto;
  display: block;
  background-color: rgba(0,0,0,0.8);
  line-height: 20px;
  color: #fff;
  bottom: 0;
  left: 0;

  z-index: 101;
  padding: 10px;
  text-align: center;
}
.cookie_block span {
  font-size: 12px;
  text-align: center;
  margin: auto;
}
.cookie_link{
  display: inline-block;
  color: #fff !important;
  padding: 2px 12px;
  line-height: 20px;
  font-size: 12px;
  white-space: nowrap;
  margin: 0 10px;
  margin-top: 10px;
  text-decoration: underline;

}
.cookie_link:last-child {
  border: 1px solid #fff;
  border-radius: 20px;
  text-decoration: none;
  cursor: pointer;
  padding: 6px 17px;
  text-transform: uppercase;
}
