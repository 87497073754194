//# Grid
//
//*

html,
body {
    position: relative;
    height: 100%;
}

body

.o-box {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    padding: 0 30px;
    @include zoom(max-width, 900px);
}
