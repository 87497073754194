//# Aplication footer
//
//*

.c-footer {
    position: relative;
    min-height: 100vh;
    color: $white;

    &-iframe {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;

        iframe {
            width: 100vw;
            height: 70vw;
            min-width: 182.77vh;
            min-height: 120vh;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    &-wrap {
        position: relative;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        @include zoom(padding-bottom, 30px);
        @include zoom(padding-top, 30px);
    }

    &-info {
        margin: auto 0;
        text-align: center;
    }

    &-logo {
        @include zoom(margin-bottom, 20px);

        svg {
            @include zoom(width, 502px);
            @include zoom(height, 96px);

            @include media-breakpoint-down(xs) {
                max-width: 100%;
                height: auto;
            }
        }
    }

    &-text {
        margin: 0;

        @include zoom(font-size, 42px);

        @include media-breakpoint-down(xs) {
            font-size: 25px;
        }
    }

    &-social {

        &__list {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0;
            margin: 0;
            list-style: none;

            @include zoom(margin-left, -25px);
            @include zoom(margin-right, -25px);

            @include media-breakpoint-down(xs) {
                margin-left: -15px;
                margin-right: -15px;
            }
        }

        &__item {
            @include zoom(padding-left, 25px);
            @include zoom(padding-right, 25px);

            @include media-breakpoint-down(xs) {
                padding-left: 15px;
                padding-right: 15px;
            }
        }

        &__link {
            display: block;
            color: currentColor;

            &:hover {
                color: $red;
            }
        }

        .facebook {

            svg {
                @include zoom(width, 16px);
                @include zoom(height, 30px);
            }
        }

        .twitter {

            svg {
                @include zoom(width, 36px);
                @include zoom(height, 36px);
            }
        }

        .instagram {

            svg {
                @include zoom(width, 30px);
                @include zoom(height, 30px);
            }
        }
    }

    &-copy {
        @include zoom(font-size, 14px);
        @include zoom(line-height, 18px);
        @include zoom(margin-top, 30px);

        @include media-breakpoint-down(xs) {
            margin-top: 30px;
        }
    }
}

.footer-copyrights {
    width: 100%;
    text-align: center;
    margin-top: 50px;
    a {
        font-size: calc(100vw * 14 / 1024);
        text-decoration: underline;
        color: #fff;
        display: block;
        margin-bottom: 20px;
        @include media-breakpoint-down(md) {
            font-size: 14px;
        }
    }
}
