.c-video {
    position: relative;
    background-color: $black;
    color: $white;
    min-height: 100vh;
    display: flex;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    // @include zoom('padding-right', 30px);
    // @include zoom('padding-left', 30px);

    &-wrap {
        width: 100%;
        min-height: 0px;
    }

    &-title {
        text-transform: uppercase;
        text-align: center;
        font-weight: 900;
        line-height: inherit;
        margin: 0;
        transition: opacity $transition;

        @include zoom(padding-right, 40px);
        @include zoom(padding-left, 40px);
        @include zoom(font-size, 80px);

        @include media-breakpoint-down(md) {
            font-size: 60px;
        }

        @include media-breakpoint-down(xs) {
            font-size: 35px;
        }

        .is-playing & {
            opacity: 0;
            visibility: hidden;
        }
    }

    &-play {
        transition: opacity $transition;
        @include zoom(margin-bottom, 30px);

        svg {
            fill: currentColor !important;
            width: 70px;
            height: 70px;
            margin-left: 15px;
        }

        @include media-breakpoint-up(lg) {
            display: none;
        }

        .is-playing & {
            opacity: 0;
            visibility: hidden;
        }
    }

    &-info {
        // position: absolute;
        // left: 0;
        // top: 0;
        // height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 80px 0;
        z-index: 1;
        cursor: url(../img/cursor/play2.svg) 0 0, auto;

        .is-playing & {
            cursor: url(../img/cursor/pause.svg) 0 0, auto;
        }
    }

    &-iframe {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
    }

    iframe {
        width: 100vw;
        height: 80vw;
        min-width: 177.77vh;
        min-height: 142.22vh;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        @include media-breakpoint-down(sm) {
            width: 115vw;
        }
    }
}
