//# Scaffolding
//
//* Reset

html {
    font-size: 10px;
}

body {
    fill: $body-color;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility !important;
    // cursor: url(../img/cursor/default.svg) 0 0, auto;

    @include zoom(font-size, 15px);

    a,
    iframe {
        // cursor: url(../img/cursor/pointer.svg) 22 0, auto;
    }
}

* {
    outline: none !important;
}


//## Transitions
//
//*

button,
.btn,
a {
    transition: $transition;
}


//## Text
//
//*

//** text selection
// ::selection {
//     background-color: lighten(theme-color("primary"), 50%);
// }

h1, .h1, h2, .h2, h3, .h3,
h4, .h4, h5, .h5, h6, .h6 {
    display: block;
}

.h1, h1 {
    font-size: 54px;
    line-height: 68px;
    font-weight: 300;

    @include media-breakpoint-down(sm) {
        font-size: 32px;
        line-height: 36px;
    }
}

.h2, h2 {
    font-size: 48px;
    line-height: 60px;

    @include media-breakpoint-down(sm) {
        font-size: 32px;
        line-height: 36px;
    }
}

.h3, h3 {
    font-size: 38px;
    line-height: 50px;
}

.h4, h4 {
    font-size: 31px;
    line-height: 39px;
}

// .h5, h5 {}

// .h6, h6 {}

.text-md {
    font-size: 20px;
    line-height: 26px;

    @include media-breakpoint-down(md) {
        font-size: 18px;
        line-height: 24px;
    }

    @include media-breakpoint-down(xs) {
        font-size: 16px;
        line-height: 20px;
    }
}

.text-lg {
    font-size: 22px;
    line-height: 28px;

    @include media-breakpoint-down(sm) {
        font-size: 18px;
        line-height: 24px;
    }
}

.text-xl {
    font-size: 24px;
    line-height: 30px;

    @include media-breakpoint-down(xs) {
        font-size: 20px;
        line-height: 26px;
    }
}

@each $color, $value in $colors {
    @include text-emphasis-variant(".text-#{$color}", $value);
}

@each $class, $value in $font-weights {
    @include font-weight(".font-weight-#{$class}", $value);
}


//## Forms
//
//*

textarea {
    resize: vertical;
}


//## Misc
//
//*

abbr[title] {
    text-decoration: none;
    border-bottom: 0;
    cursor: inherit !important;
}
