@keyframes animation_9 {
    0% {
        transform: matrix(1, 0, 0, 1, 0, 0);
        opacity: 1;
        animation-timing-function: ease-in-out
    }

    100% {
        transform: matrix(1, 0, 0, 1, 0, 20);
        opacity: 1;
    }
}

.c-section {
    position: relative;
    padding-left: constant(safe-area-inset-left);
    padding-left: env(safe-area-inset-left);
    padding-right: constant(safe-area-inset-right);
    padding-right: env(safe-area-inset-right);

    &-wrap {
        min-height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &-arrow {
        display: inline-block;
        vertical-align: middle;
        position: absolute;
        width: 100%;
        text-align: center;
        transform: matrix(1, 0, 0, 1, 0, 0);
        animation-name: animation_9;
        animation-duration: 1.2s;
        animation-iteration-count: infinite;
        animation-direction: alternate;
        animation-fill-mode: both;
        animation-play-state: running;
        color: inherit;

        @include zoom(bottom, 30px);

        svg {
            vertical-align: middle;

            @include zoom(width, 22px);
            @include zoom(height, 32px);
        }
    }
}

