.c-top {
    background-color: $black;
    color: $white;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    // cursor: url(../img/cursor/wait.svg) 22 0, auto;

    .o-box {
        pointer-events: none;
    }

    &-bg {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        opacity: .5;
        transition: opacity $transition;

        .is-playing & {
            opacity: 0;
        }
    }

    &-info {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        transition: opacity $transition;
        position: relative;
        z-index: 1;
        padding: 120px 0;

        .is-playing & {
            opacity: 0.1;
        }

        &__word {
            text-transform: uppercase;
            font-weight: 900;

            @include zoom(padding-left, 10px);
            @include zoom(padding-right, 10px);
            @include zoom(font-size, 180px);
            @include zoom(line-height, 200px);

            @include media-breakpoint-down(md) {
                font-size: 100px;
                line-height: 120px;
            }

            @include media-breakpoint-down(xs) {
                font-size: 60px;
                line-height: 100px;
            }
        }
    }

    &-video {
        opacity: 0;
        transition: $transition;
        visibility: hidden;
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        overflow: hidden;

        .is-playing & {
            opacity: 1;
            visibility: visible;
        }

        video {
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            object-fit: cover;
        }
    }

    &-action {
        display: inline-flex;
        flex-direction: column;
        vertical-align: middle;
        justify-content: center;
        position: absolute;
        width: 100%;
        text-align: center;
        transition: opacity $transition;
        pointer-events: none;

        .is-playing & {
            opacity: 0.1;
        }

        @include zoom(font-size, 12px);
        @include zoom(bottom, 30px);

        &__icon {
            display: inline-block;
            margin-bottom: 5px;

            svg {
                width: 40px;
                height: 40px;
            }
        }

        &__text {
            display: inline-block;
        }
    }
}
