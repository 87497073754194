.c-red {
    min-height: 120vh;
    display: flex;

    &-wrap {
        position: sticky;
        top: 0;
    }

    &-title {
        text-transform: uppercase;
        text-align: center;
        font-weight: 900;
        margin: 0;
        line-height: 1.16;
        position: relative;
        display: inline-block;
        color: $white;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-image: url(../img/water.gif);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;

        @include zoom(font-size, 100px);

        @include media-breakpoint-down(md) {
            font-size: 60px;
        }

        @include media-breakpoint-down(xs) {
            font-size: 40px;
        }

        &:before {
            z-index: -2;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-image: inherit;
        }

        &:after {
            position: absolute;
            z-index: -1;
            top: .125em;
            right: .125em;
            bottom: .125em;
            left: .125em;
            background-color: $black;
        }
    }
}