.c-info {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: $white;

    &-col {
        @include zoom(max-width, 330px);

        &.right {
            margin-left: auto;

            @include media-breakpoint-down(md) {
                margin-left: 0;
            }
        }
    }

    &-wrap {
        min-height: 120vh;
        justify-content: flex-start;
    }

    &-title {
        font-weight: 700;

        @include zoom(font-size, 36px);
        @include zoom(line-height, 38px);
        @include zoom(margin-bottom, 30px);
    }

    &-text {

        p {
            margin: 0;
        }
    }
}