//# Fonts
//
//*

@mixin font-face($family, $filepath, $weight: normal, $style: normal) {
	@font-face {
		font-family: $family;
		font-display: swap;
		src: local($family), url("#{$filepath}.woff") format("woff"), url("#{$filepath}.ttf") format("truetype");
		font-weight: $weight;
		font-style: $style;
	}
}

//** Font weight hint
// 100 – thin
// 200 – extra light
// 300 – light
// 400 – regular (normal)
// 500 – medium
// 600 – semibold
// 700 – bold
// 800 – extrabold
// 900 – black

// @include font-face('RobotoNew', '../fonts/Roboto/Roboto-Thin', 100);
// @include font-face('RobotoNew', '../fonts/Roboto/Roboto-ThinItalic', 100, italic);
// @include font-face('RobotoNew', '../fonts/Roboto/Roboto-ExtraLight', 200);
// @include font-face('RobotoNew', '../fonts/Roboto/Roboto-ExtraLightItalic', 200, italic);
// @include font-face('RobotoNew', '../fonts/Roboto/Roboto-Light', 300);
// @include font-face('RobotoNew', '../fonts/Roboto/Roboto-LightItalic', 300, italic);
@include font-face("RobotoNew", "../fonts/Roboto/Roboto-Regular", 400);
// @include font-face('RobotoNew', '../fonts/Roboto/Roboto-RegularItalic', 400, italic);
// @include font-face('RobotoNew', '../fonts/Roboto/Roboto-Medium', 500);
// @include font-face('RobotoNew', '../fonts/Roboto/Roboto-MediumItalic', 500, italic);
// @include font-face('RobotoNew', '../fonts/Roboto/Roboto-SemiBold', 600);
// @include font-face('RobotoNew', '../fonts/Roboto/Roboto-SemiBoldItalic', 600, italic);
@include font-face("RobotoNew", "../fonts/Roboto/Roboto-Bold", 700);
// @include font-face('RobotoNew', '../fonts/Roboto/Roboto-BoldItalic', 700, italic);
// @include font-face('RobotoNew', '../fonts/Roboto/Roboto-ExtraBold', 800);
// @include font-face('RobotoNew', '../fonts/Roboto/Roboto-ExtraBoldItalic', 800, italic);
@include font-face("RobotoNew", "../fonts/Roboto/Roboto-Black", 900);
// @include font-face('RobotoNew', '../fonts/Roboto/Roboto-BlackItalic', 900, italic);

.footer-copyrights {
	width: 100%;
	text-align: center;
	margin-top: 50px;
}
